import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import RaderChart from "components/raderChartLoadable";
import MakerRanking from "components/makerRankingLoadable";
import Socials from "components/socials";
import TOC from "components/toc";
import AccentBox from 'components/accentBox';
import SmallBox from 'components/smallBox';
import Lineup from 'components/lineup';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "makers/lenovo"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    lenovo_wordcloud: file(relativePath: { eq: "pcmaker/lenovo_wordcloud.png"}) { ...normalImg },
    lenovo: file(relativePath: { eq: "pcmaker/lenovo.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`レノボの評判 - 世界シェアNo1の安心感と圧倒的コストパフォーマンス`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="lenovo_wordcloud" alt="レノボの評判" fadeIn={false} mdxType="Image" />
    <p>{`レノボは、2004年にIBMのパソコン部門を買収してパソコン事業を始めた中国の会社である。`}</p>
    <p>{`世界シェアはトップであり、コストパフォーマンスもデルと並んで非常に高い。しかしサポートが最悪など悪評もそれなりにある。`}</p>
    <p>{`本記事でのレノボの評判を分析し、購入の判断へ役立てることを目指す。また、実際に売れているモデルや他社比較も同時に行っていく。尚、他メーカーと比較検討したい場合は`}<Link to="/pcmaker/" mdxType="Link">{`パソコンメーカーおすすめランキング`}</Link>{`も併せて参照して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "レノボのラインナップと特徴",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AC%E3%83%8E%E3%83%9C%E3%81%AE%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%8A%E3%83%83%E3%83%97%E3%81%A8%E7%89%B9%E5%BE%B4",
        "aria-label": "レノボのラインナップと特徴 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レノボのラインナップと特徴`}</h2>
    <Lineup {...props} maker="lenovo" mdxType="Lineup" />
    <p>{`ラインナップは`}<strong parentName="p"><em parentName="strong">{`デスクトップの一体型からゲーミングノートまで全てを網羅する全方位型のラインナップ`}</em></strong>{`であり、ブランドとしてはエントリークラスの`}<em parentName="p">{`IdeaPad`}</em>{`、プレミアモデルの`}<em parentName="p">{`YOGA`}</em>{`、ゲーミングの`}<em parentName="p">{`LEGION`}</em>{`、法人向けの`}<em parentName="p">{`ThinkPad`}</em>{`などがある。`}</p>
    <p>{`世界でNo1のシェアを持っていることから`}<strong parentName="p"><em parentName="strong">{`コストパフォーマンスは抜群に高く`}</em></strong>{`、ゲーミングのLEGIONなどは採算が取れているのか分からないような価格で販売されているものもあり、価格表記が間違っているのかと思われるぐらいである。`}</p>
    <p>{`ThinkPadは`}<em parentName="p">{`トラックポイント`}</em>{`(赤ぽち)という小型3Dスティックのようなものがキーボードの真ん中についており、スクロールやボタンクリックが可能である。詳しくは`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=6BcKRZI8IgE",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`トラックポイントの公式ガイド動画`}</a>{`を参考としてほしい。`}</p>
    <AccentBox title="サブブランド" mdxType="AccentBox">
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fthinkpad%2F%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />ThinkPad</a>: ビジネス向きの信頼性とセキュリティが高いノートパソコン、テレワーク、在宅ワークにはおすすめの一台。</li>
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fnotebooks%2Fideapad%2Fc%2Fideapad%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />IdeaPad</a>: 驚愕のコストパフォーマンス。<Link to="/note-cospa/" mdxType="Link">コスパ最強ノートパソコン</Link>の一つ。予算を抑えてつつサクサク動く。</li>
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fyoga%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />Yoga</a>: IdeaPadがダサいと感じた人のためのスリムベゼルモデル。スタイリッシュでコスパも良好。長時間バッテリー、2 in 1モデルなどバリエーションも豊富。</li>
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Flegion%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />LEGION</a>:コスパ最強のゲーミングPC、本格的な動画編集向けに購入する人もいる。</li>
    </AccentBox>
    <div align="right">>> <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885898437" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885898437" height="1" width="1" border="0" alt="" />ラインナップを見る</a></div>
    <h2 {...{
      "id": "レノボの評判、総評と項目別評価",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AC%E3%83%8E%E3%83%9C%E3%81%AE%E8%A9%95%E5%88%A4%E3%80%81%E7%B7%8F%E8%A9%95%E3%81%A8%E9%A0%85%E7%9B%AE%E5%88%A5%E8%A9%95%E4%BE%A1",
        "aria-label": "レノボの評判、総評と項目別評価 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レノボの評判、総評と項目別評価`}</h2>
    <RaderChart maker='lenovo' className={'top-margin'} mdxType="RaderChart" />
    <p>{`レノボのコスパの高さは皆が認めるところであり、特に`}<strong parentName="p"><em parentName="strong">{`エントリークラスのIdeaPadはDellのInprisonと並び全パソコン中最もコスパの良いモデル`}</em></strong>{`となっている。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`ゲーミングPCのLEGIONもゲーミングPCの中では最安の部類`}</em></strong>{`に入り、高品質で安価なマシンが欲しい場合は有力な候補となるだろう。`}</p>
    <p>{`サポートに関しては通常１年のメーカー保証期間であれば修理やトラブルの相談に応じてくれるが、１年を過ぎると１回4000円の有償電話サービスとなり、比較的渋い対応とはいえる。保証期間外ならばLenovoに修理を依頼するより他の業者を頼るのが良さそうである。`}</p>
    <p>{`デザインに関しては、まあ可もなく不可もなくといったところで別段おしゃれと感じたことは無い。`}</p>
    <p>{`他ThinkPadはビジネス用としてはセキュリティ面が優秀で、顧客情報、守秘義務のある情報を抱えるなど、万が一にもデータを漏らしてはならない場合はThinkPadは良い選択肢となるだろう。`}</p>
    <AccentBox title="レノボの項目別評価" mdxType="AccentBox">
  <li><span className="bold">コストパフォーマンス</span>・・・トップクラスに高い</li>
  <li><span className="bold">サポート</span>・・・<a href="https://support.lenovo.com/jp/ja/solutions/hf001521" target="_blank" rel="nofollow noopenner">サポートサイト</a>より、保証期間内(通例１年)のみ修理対応を受付。</li>
  <li><span className="bold">デザイン</span>・・・普通。たまにカッコいいものも見つかる程度</li>
  <li><span className="bold">人気</span>・・・当サイトでの人気。コストパフォーマンスを武器に当サイトでもかなり上位にランクイン。</li>
  <li><span className="bold">安定性</span>・・・上場企業であり業績も好調。安定した運営が見込める</li>
    </AccentBox>
    <h2 {...{
      "id": "実際に売れているモレノボはこれ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%AB%E5%A3%B2%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%83%A2%E3%83%AC%E3%83%8E%E3%83%9C%E3%81%AF%E3%81%93%E3%82%8C",
        "aria-label": "実際に売れているモレノボはこれ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際に売れているモレノボはこれ`}</h2>
    <p>{`本サイトを経由して実際によく売れているモレノボを紹介する。当サイトはパソコンにそれほど詳しくはないが、インターネットで買い物ができるなど標準的なITリテラシーを持った人をメインターゲットとしており、比較して堅実に買い物をしている人が多いと思われる。そのような層で人気が高いパソコンは下記の２つである。`}</p>
    <h3 {...{
      "id": "IdeaPad",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#IdeaPad",
        "aria-label": "IdeaPad permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IdeaPad`}</h3>
    <p>{`LenovoのエントリーモデルIdeaPadは非常に良く売れている。`}</p>
    <p>{`購入の傾向としては、10万円ギリギリの価格で購入されていることが多く、`}<strong parentName="p"><em parentName="strong">{`低コストでスペックをギリギリまで高めた高性能なパソコンが欲しい`}</em></strong>{`という需要が見て取れる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`DellのInprisonと並びコスパは世界最強の一つ`}</em></strong>{`とも言え、大量生産による歩留まり率の安定により故障確率も標準以下であることはほぼ間違えないだろう。`}</p>
    <p>{`安かろう悪かろうでは無く、安心して購入できる製品である。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fnotebooks%2Fideapad%2Fc%2Fideapad%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />Lenovo IdeaPad</a>
    </SmallBox>
    <h3 {...{
      "id": "LEGIONのゲーミングノート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#LEGION%E3%81%AE%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%83%8E%E3%83%BC%E3%83%88",
        "aria-label": "LEGIONのゲーミングノート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LEGIONのゲーミングノート`}</h3>
    <p>{`ゲーミングノートPCも非常に良く売れている。`}<strong parentName="p"><em parentName="strong">{`15万円前後で本格的なゲーミングPCが購入できる`}</em></strong>{`とあれば当然かもしれない。`}</p>
    <p>{`ゲーミングノートは端的に言えばGPUを搭載したハイスペックなノートPCで、動画編集などクリエイティブな用途に使う人も多い。`}</p>
    <p>{`お手軽に超ハイスペックなPCを求める人には良い選択肢となるだろう。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fdg%2Fnotebooks%3Ffrom%3Dsplitter%26visibleDatas%3Dfacet_Type%253A%25E3%2582%25B2%25E3%2583%25BC%25E3%2583%259F%25E3%2583%25B3%25E3%2582%25B0%26sort%3DsortBy%26resultsLayoutType%3Dgrid%26cid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt="" />LEGIONノートPC一覧</a>
    </SmallBox>
    <h2 {...{
      "id": "レノボの３大悪評",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AC%E3%83%8E%E3%83%9C%E3%81%AE%EF%BC%93%E5%A4%A7%E6%82%AA%E8%A9%95",
        "aria-label": "レノボの３大悪評 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レノボの３大悪評`}</h2>
    <p>{`悪評は購入時の期待値と実際の乖離から生じるものである。よって`}<strong parentName="p"><em parentName="strong">{`購入前に齟齬が出そうな点をあらかじめ抑えておくことが重要`}</em></strong>{`となる。ここではネットでの評判を抽出し、その妥当性についてコメントしていく。`}</p>
    <h3 {...{
      "id": "修理費が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BF%AE%E7%90%86%E8%B2%BB%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "修理費が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`修理費が高い`}</h3>
    <p>{`その通りである。製品のコストが安いという事は原価が高いという事である。システムボードなどがやられると、作業費と原価や送料でかなり割高となってしまい、`}<strong parentName="p"><em parentName="strong">{`買い替えた方が安い`}</em></strong>{`という状況に容易になる。`}</p>
    <p><a parentName="p" {...{
        "href": "https://support.lenovo.com/jp/ja/solutions/hf000982",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`引取り修理サービス料金`}</a>{`を見ると修理に掛かる目安が確認できる。`}</p>
    <p>{`正直な話、レノボのパソコンが壊れたら、`}<strong parentName="p"><em parentName="strong">{`修理に出すよりも買い替えた方が良い`}</em></strong>{`というのが感想である。あるいは修理店に持ち込むというのも手であるが、そもそも部品原価が高いため、そこまで価格差は出ないであろう。`}</p>
    <p>{`１年間はメーカー保証が付いているため無償となるが、それ以上は`}<strong parentName="p"><em parentName="strong">{`心配ならば延長保証に加入しておくことをおすすめ`}</em></strong>{`する。`}</p>
    <h3 {...{
      "id": "故障が多い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%95%85%E9%9A%9C%E3%81%8C%E5%A4%9A%E3%81%84",
        "aria-label": "故障が多い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`故障が多い`}</h3>
    <p>{`初期不良の口コミが多いものの心配には当たらない。偶然不具合に当たった人が不満を述べているに過ぎない。`}</p>
    <p>{`特にユーザー数が多いパソコンは不満がネット上に多くても仕方がないであろう。また、初期不良の対応及び、メーカーの一年保証は有効である。`}</p>
    <p>{`電子機器の故障率は使用して１年目までは故障率が高く、２年から数年間は低い、そしてその後製品寿命から再び故障率が上昇する。故障率のグラフを書くとちょうどバスタブ(浴槽)のようになるため`}<em parentName="p">{`バスタブカーブ`}</em>{`とも呼ばれている。`}</p>
    <p>{`1ヵ月もしない打ちに動作が不安定になりましたなどの書き込みもあるが、`}<strong parentName="p"><em parentName="strong">{`電子機器とはそういうものだと知り冷静に対処すべき`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "サポートが最悪",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%8C%E6%9C%80%E6%82%AA",
        "aria-label": "サポートが最悪 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポートが最悪`}</h3>
    <p>{`レノボはサポートが最悪というネットへの書き込みは少なからずある。ただ修理で異常に高い見積もりが提出された、オペレータの対応が悪かったなどを`}<strong parentName="p"><em parentName="strong">{`腹いせに書き込んでいるだけ`}</em></strong>{`である。`}</p>
    <p>{`レノボのサポート対応は実はNECパーソナルコンピュータが運営を行っており、`}<strong parentName="p"><em parentName="strong">{`国内での対応となるし、丁寧なサポートが期待できる`}</em></strong>{`。`}</p>
    <h2 {...{
      "id": "レノボの評判まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AC%E3%83%8E%E3%83%9C%E3%81%AE%E8%A9%95%E5%88%A4%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "レノボの評判まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レノボの評判まとめ`}</h2>
    <p>{`以上、`}<strong parentName="p"><em parentName="strong">{`元IBMのパソコン事業という歴史があり安心の品質とコストパフォーマンスの高さがレノボの魅力`}</em></strong>{`である。商品のラインナップの確認や購入を検討している場合は下記から公式サイトへ飛ぶことができる。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885898437" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885898437" height="1" width="1" border="0" alt="" />Lenovo</a>
    </SmallBox>
    <h2 {...{
      "id": "他社との比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BB%96%E7%A4%BE%E3%81%A8%E3%81%AE%E6%AF%94%E8%BC%83",
        "aria-label": "他社との比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`他社との比較`}</h2>
    <p>{`パソコンの種類や用途により得意なメーカーは分かれるため、当サイトでは選択肢に応じたランキングを制作している。`}</p>
    <p>{`デフォルトは当サイトの人気順であるが、探している種類やこだわりがあれば選択し、自分にあったパソコンを探してほしい。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`レノボと似ているパソコンとしてはデルやゲーミングPCならパソコン工房を同時に確認したい`}</em></strong>{`。リンクをクリックすると公式サイトへ移動します。`}</p>
    <MakerRanking highlight='lenovo' mdxType="MakerRanking" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      